import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `https://api.baraatco.com/api/v1/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("baratUserToken");
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("baratUserToken");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
