import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import swal from "sweetalert";
import { variable } from "../components/Variable";
import { blockUserApi } from "..//components/APIs/Api";
import defaultImg from "../../images/defaulImg.jpg";
import Pagination from "../components/Pagination";
import { toast, ToastContainer } from "react-toastify";
import { clearFields } from "redux-form";
export default function UserManagement(props) {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersList, setUsersList] = useState();

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  async function userListData() {
    setLoader(true);
    const res = await userManagementList(itemsPerPage, currentPage);
    setUsersList(res);
    setLoader(false);
  }

  async function blockUser(id, status) {
    const res = await blockUserApi(id, status);
    setLoader(false);
    userListData();
    if (status == 1) {
      toast.success("User Enabled", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("User Disabled", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const totalItems = usersList?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    userListData();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    userListData();
  }, []);

  console.log(usersList, "userssds");
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Lottie style={{ height: "400px" }} animationData={animationData} />
        </div>
      ) : (
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <PageTitle activeMenu="Users List" motherMenu="Users" />
          <Col>
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE NUMBER</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item.id}>
                        {console.log(usersList)}

                        <td>
                          <img
                            height={60}
                            width={60}
                            src={item.image || defaultImg}
                            alt="image"
                            className="profile-photo"
                            onError={onImageError}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>
                          <span>{item.countryCode}</span>-{item.number}
                        </td>
                        <td>
                          {item.isActive == 1 ? (
                            <Badge variant="success light">Active</Badge>
                          ) : (
                            <Badge variant="danger light">Disabled</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  variable.id = item.id;
                                  props.history.push("/user-details");
                                }}
                              >
                                View Details
                              </Dropdown.Item>
                              {item.isActive == 1 ? (
                                <Dropdown.Item
                                  onClick={() => blockUser(item.id, 0)}
                                >
                                  InActive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => blockUser(item.id, 1)}
                                >
                                  Active
                                </Dropdown.Item>
                              )}
                              {/* <Dropdown.Item >Delete</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          {/* {loader && <Spinner />} */}
        </div>
      )}
    </>
  );
}
