import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
// let file = "";

const ImportExcl = ({ setExcelData, excelData, handleVendorsFile }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formattedData = parsedData.slice(1).map((row) => {
          return {
            email: row[0],
            countryCode: row[1],
            number: row[2],
            name: row[3],
            category: row[4],
            instagram: row[5],
            facebook: row[6],
            website: row[7],
          };
        });

        setExcelData({ vendors: formattedData });
        handleVendorsFile(file?.name);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        ref={fileInputRef}
        accept=".xlsx,.xls"
        onChange={handleFileChange}
      />

      <button onClick={handleButtonClick} className="btn btn-sm">
        import
      </button>
    </div>
  );
};

export default ImportExcl;
