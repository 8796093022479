import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { variable } from "../components/Variable";
import { ToastContainer, toast } from "react-toastify";
import { updateCategoryApi } from "../components/APIs/Api";
import Button from "../components/Button";
export default function EditCategory({
  show,
  onHide,
  categoryLists,
  editData,
}) {
  const [file, setFile] = useState();
  const [category, setCategory] = useState(editData?.name);
  const [loader, setLoader] = useState(false);

  //Add Submit data
  async function handleAddFormSubmit(event) {
    setLoader(true);
    event.preventDefault();

    const response = await updateCategoryApi(editData?.id, file, category);

    if (response.status === 200) {
      categoryLists();
      setLoader(false);
      toast.success("Edit Success!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      onHide(false);
    } else {
      setLoader(false);
      toast.error(response?.data?.error?.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <div className="">
          <form onSubmit={handleAddFormSubmit}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Category Details</h4>
              <button type="button" className="close" onClick={() => onHide()}>
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column mb-3">
                        <label className="text-black font-w500">Image</label>
                        <input
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                      <img
                        className="profile-photo"
                        src={editData?.image}
                        height={60}
                        width={60}
                      />
                    </div>

                    <label className="text-black font-w500">
                      Category Name
                    </label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={category}
                        name="Date_Join"
                        onChange={(e) => setCategory(e.target.value)}
                        placeholder="category"
                      />
                      <span className="validation-text"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button loader={loader}></Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
