import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { viewUserDetails } from "../components/APIs/Api";
import { variable } from "../components/Variable";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaulImg.jpg";
import animationData from "../../images/loding.json";

export default function UserDetail() {
  const [UserDetails, setUserDetails] = useState();
  const [loading, setLoding] = useState(true);

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  async function viewUserDetailsApi() {
    const res = await viewUserDetails(variable.id);
    setUserDetails(res);
    setLoding(false);
  }

  useEffect(() => {
    viewUserDetailsApi();
    console.log(variable.id, "variableis");
  }, []);

  let item = UserDetails?.data?.data;
  console.log(item, "userGHHGj");

  return (
    <>
      {loading ? (
        <Lottie style={{ height: "400px" }} animationData={animationData} />
      ) : (
        <div>
          <div className="page-titles">
            <h4>Users Details</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="user-management">Users</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link>Users Details</Link>
              </li>
            </ol>
          </div>
          {/* <Col> */}
          <Card style={{ width: "70%" }}>
            <Card.Header>
              <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
                <div>
                  <img
                    src={item?.image || defaultImg}
                    width={110}
                    height={110}
                    className="profile-photo"
                    onError={onImageError}

                  />
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">{item?.name}</h3>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>Email</h5>
                    <p>{item?.email}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 style={{ margin: "0" }}>phone</h5>
                    <p style={{ margin: "0" }}>
                      <span>{item?.countryCode}</span>-{item?.number}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>Created At</h5>
                  <p className=" px-4 py-2">
                    {item?.created_at.length > 10
                      ? item?.created_at.slice(0, 10)
                      : item?.created_at}
                  </p>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center pb-3">
                  <h5>Address</h5>
                  <p className="">{item?.address}</p>
                </div> */}
              </div>
            </Card.Body>
          </Card>
          {/* </Col> */}
        </div>
      )}
    </>
  );
}
