import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
// import 'react-phone-number-input/style.css'
import { toast, ToastContainer } from "react-toastify";
import "./AddServiceProvider.css";
import { addServiceApi } from "../components/APIs/Api";
import Button from "../components/Button";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function AddServiceProvider({
  show,
  onHide,
  servicsProvidersList,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [nameEroor, setNameError] = useState("");
  const [numberEroor, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [instagram, setInstagram] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [loader, setLoader] = useState(false);

  //Add Submit data
  async function hendelAddService(event) {
    event.preventDefault();
    let serviceAddFormData = {
      name,
      email,
      number: phone,
      password,
      countryCode: "+" + countryCode,
      instagram,
      facebook,
      website,
    };
    if (name.length < 3) {
      setNameError("Invalid name");
      return;
    } else if (email.length === 0) {
      setEmailError("Enter mail");
      return;
    } else if (name.length === 0) {
      setEmailError("Enter name");
      return;
    } else if (password.length < 6) {
      setPasswordError("password greaterthen 6");
      return;
    } else if (phone.length < 6) {
      setNumberError("number greaterthen 6");
      return;
    } else if (phone.length > 15) {
      setNumberError("number lessThen 15");
      return;
    }
    setLoader(true);
    try {
      const response = await addServiceApi(serviceAddFormData);
      if (response?.status === 200) {
        setLoader(false);
        onHide(false);
        servicsProvidersList();
        toast.success("Added successful!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.error?.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form onSubmit={hendelAddService}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add Service Provider Details</h4>
            <button type="button" className="close" onClick={() => onHide()}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <span className="text-danger">{nameEroor}</span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Email</label>
                  <div className="contact-name">
                    <input
                      type="Enter email"
                      className="form-control"
                      required="required"
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="text-danger">{emailError}</span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Phone</label>
                  <div className="d-flex">
                    <PhoneInput
                      prefix="+"
                      type="text"
                      enableSearch={true}
                      country={"us"}
                      //  value={countryCode}
                      className="phoneInput"
                      required="required"
                      onChange={(e) => setCountryCode(e)}
                    />
                    <input
                      type="Enter number"
                      required="required"
                      className="form-control"
                      autocomplete="off"
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter Phone Number"
                    />
                    <span className="validation-text">{nameEroor}</span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Instagram</label>
                  <input
                    type="text"
                    className="form-control"
                    value={instagram}
                    placeholder="Enter Instagram Id"
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                  <div className="form-group">
                    <label className="text-black font-w500">Facebook</label>
                    <input
                      type="text"
                      className="form-control"
                      value={facebook}
                      placeholder="Enter Facebook Id"
                      onChange={(e) => setFacebook(e.target.value)}
                    />
                  </div>
                  {/* <span className="text-danger">{passwordError}</span> */}
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Website Link</label>
                  <input
                    type="text"
                    className="form-control"
                    value={website}
                    placeholder="Enter Website Link"
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="text-black font-w500">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="text-danger">{passwordError}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button loader={loader} />
          </div>
        </form>
      </div>
      {/* </div> */}
    </Modal>
  );
}
