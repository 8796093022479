import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { variable } from "../components/Variable";
import { ToastContainer, toast } from "react-toastify";
import Button from "../components/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { editServiceApi } from "../components/APIs/Api";

export default function EditServiceProvider({
  show,
  onHide,
  servicsProvidersList,
  editData,
}) {
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState();
  const [name, setName] = useState(editData?.name);
  const [email, setEmail] = useState(editData?.email);
  const [phone, setPhone] = useState(editData?.number);
  const [password, setPassword] = useState();
  const [instagramId, setInstagramId] = useState(editData?.instagram);
  const [website, setWebLink] = useState(editData?.website);
  const [description, setDescription] = useState(editData?.description);
  const [countryCode, setCountryCode] = useState(editData?.countryCode);
  const [category, setCategory] = useState(editData?.category);

  //Add Submit data
  async function handleAddFormSubmit(event) {
    setLoader(true);
    event.preventDefault();
    let EditFormData = {
      file,
      name,
      email,
      phone,
      password,
      countryCode: "+" + countryCode,
      userId: variable.id,
      category,
      instagramId,
      website,
      description,
    };
    const responce = await editServiceApi(EditFormData, editData?.id);

    if (responce.status === 200) {
      setLoader(false);
      toast.success("Update succesful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      servicsProvidersList();
      onHide();
    } else {
      setLoader(false);
      toast.error(responce?.data?.error?.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <div className="">
          <form onSubmit={handleAddFormSubmit}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Edit Category Details</h4>
              <button type="button" className="close" onClick={() => onHide()}>
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <div className="d-flex align-items-center">
                      <div className="d-flex flex-column mb-3">
                        <label className="text-black font-w500">Image</label>
                        <input
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                        />
                      </div>
                      {!file?.name?.length > 0 && (
                        <img
                          className="profile-photo"
                          src={editData?.image}
                          height={60}
                          width={60}
                        />
                      )}
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          placeholder="name"
                          onChange={(e) => setName(e.target.value)}
                        />
                        {/* <span className="text-danger">{nameEroor}</span> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          placeholder="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <span className="text-danger">{emailError}</span> */}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Phone</label>
                      <div className="d-flex">
                        <PhoneInput
                          prefix="+"
                          type="text"
                          enableSearch={true}
                          value={countryCode}
                          className="phoneInput"
                          onChange={(e) => setCountryCode(e)}
                        />
                        <input
                          type="number"
                          className="form-control"
                          value={phone}
                          // autocomplete="off"
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Enter Phone Number"
                        />
                        {/* <span className="validation-text">{PhoneError}</span> */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {/* <span className="text-danger">{passwordError}</span> */}
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        InstagramId
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={instagramId}
                        placeholder="instragramId"
                        onChange={(e) => setInstagramId(e.target.value)}
                      />
                      {/* <span className="text-danger">{passwordError}</span> */}
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        Website Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={website}
                        placeholder="Website Link"
                        onChange={(e) => setWebLink(e.target.value)}
                      />
                      {/* <span className="text-danger">{passwordError}</span> */}
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">Category</label>
                      <input
                        type="number"
                        className="form-control"
                        value={category}
                        placeholder="Category"
                        onChange={(e) => setCategory(e.target.value)}
                      />
                      {/* <span className="text-danger">{passwordError}</span> */}
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        Description
                      </label>
                      <textarea
                        type="text"
                        value={description}
                        className="form-control"
                        placeholder=""
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {/* <span className="text-danger">{passwordError}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button loader={loader}></Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
