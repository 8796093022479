import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import "../modal/AddServiceProvider.css";
import Pagination from "../components/Pagination";
import AddServiceProvider from "../modal/AddServiceProvider";
import { servicesProviderListApi } from "../components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { variable } from "../components/Variable";
import EditServiceProvider from "../modal/EditServiceProvider";
import { toast } from "react-toastify";
import ImportExcl from "../components/ImportExcl";
import { blockServiceApi, addMultiVendorApi } from "../components/APIs/Api";
import defaultImg from "../../images/defaulImg.jpg";

export default function ServiceProviderList(props) {
  const [editModal, setEditModal] = useState(false);
  const [providerList, setProviderList] = useState();
  const [editData, setEditData] = useState();
  const [loader, setLoader] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  console.log(excelData);
  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const totalItems = providerList?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  async function serviceProvidersList() {
    setLoader(true);
    const res = await servicesProviderListApi(itemsPerPage, currentPage);
    setProviderList(res);
    setLoader(false);
  }

  async function handleBlock(id, status) {
    const res = await blockServiceApi(id, status);
    setLoader(false);
    serviceProvidersList();
    if (status == 1) {
      toast.success("Active successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("DeActive successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  function handleVendorsFile(name) {
    setFileName(name);
  }
  async function handleMultipleVendors(e) {
    // e.preventDefault();
    setLoader(true);
    try {
      const res = await addMultiVendorApi(excelData);
      setLoader(false);
      serviceProvidersList();
      toast.success("Add vendors successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFileName("");
      // setExcelData("");
    } catch (e) {
      setLoader(false);
      toast.error(e?.response?.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  console.log(fileName);
  useEffect(() => {
    serviceProvidersList();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    serviceProvidersList();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [postModal, setPostModal] = useState(false);
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Lottie style={{ height: "400px" }} animationData={animationData} />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <PageTitle
              activeMenu="Service Provider List"
              motherMenu="Service Provider"
            />
            <div>
              {fileName?.length > 0 ? (
                <button className="btn btn-sm" onClick={handleMultipleVendors}>
                  Export Data
                </button>
              ) : (
                <ImportExcl
                  setExcelData={setExcelData}
                  excelData={excelData}
                  handleVendorsFile={handleVendorsFile}
                />
              )}
              <p>{fileName}</p>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            <Button className="btn btn-sm" onClick={() => setPostModal(true)}>
              Add New +
            </Button>
          </div>

          <Col>
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>VENDOR NAME</strong>
                      </th>
                      <th>
                        <strong>VENDOR email</strong>
                      </th>
                      <th>
                        <strong>PHONE</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {providerList?.data?.data?.map((item) => (
                      <tr key={item.id}>
                        <td className="serviceProfile">
                          <img
                            height={60}
                            width={60}
                            src={item.image || defaultImg}
                            alt="img"
                            className="profile-photo"
                            onError={onImageError}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>
                          <span>{item.countryCode}</span>
                          {item.number}
                        </td>
                        <td>
                          {item.isActive == 1 ? (
                            <Badge variant="success light">Active</Badge>
                          ) : (
                            <Badge variant="danger light">Blocked</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  variable.id = item?.id;
                                  props.history.push(
                                    "/service-provider-details"
                                  );
                                }}
                              >
                                View Details
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setEditData(item);
                                  setEditModal(true);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              {item.isActive == 1 ? (
                                <Dropdown.Item
                                  onClick={() => handleBlock(item.id, 0)}
                                >
                                  DeActive
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => handleBlock(item.id, 1)}
                                >
                                  Active
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {providerList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {providerList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <AddServiceProvider
            show={postModal}
            servicsProvidersList={serviceProvidersList}
            onHide={() => setPostModal(false)}
          />
          {editData && editModal && (
            <EditServiceProvider
              show={editModal}
              servicsProvidersList={serviceProvidersList}
              onHide={() => setEditModal(false)}
              editData={editData}
            />
          )}
        </div>
      )}
    </>
  );
}
