import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import { toast } from "react-toastify";
import AddCategory from "../modal/AddCategory";
import EditCategory from "../modal/EditCategory";
import { categoryListApi, deleteCategoryApi } from "../components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import Pagination from "../components/Pagination";
import defaultImg from "../../images/defaulImg.jpg";

export default function CategoryList() {
  const [categoryList, setCategotyList] = useState();
  const [editData, setEditData] = useState();
  const [postModal, setPostModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(true);

  const itemsPerPage = 10;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const totalItems = categoryList?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  async function categoryLists() {
    setLoader(true);
    const res = await categoryListApi(itemsPerPage, currentPage);
    setCategotyList(res);
    setLoader(false);
  }

  async function handleDeleteCategory(id) {
    scrollToTop();
    setLoader(true);
    const res = await deleteCategoryApi(id);
    if (res.status === 200) {
      toast.success("Category Deleted successful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
      categoryLists();
    } else {
      setLoader(false);
      toast.error("error !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };
  useEffect(() => {
    categoryLists();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    categoryLists();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Lottie style={{ height: "400px" }} animationData={animationData} />
        </div>
      ) : (
        <div>
          <PageTitle activeMenu="Category List" motherMenu="Category" />
          <div className="d-flex justify-content-end mb-3">
            <Button className="btn btn-sm" onClick={() => setPostModal(true)}>
              Add New +
            </Button>
          </div>
          <Col>
            <Card>
              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Created At</strong>
                      </th>
                      <th>
                        <strong>action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList?.data?.data?.map((item) => (
                      <tr>
                        <td>
                          <img
                            height={50}
                            width={50}
                            src={item.image || defaultImg}
                            alt="image"
                            className="profile-photo"
                            onError={onImageError}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{moment(item?.created_at).format("ll")}</td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setEditData(item);
                                  setEditModal(true);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  handleDeleteCategory(item?.id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {categoryList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {categoryList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <AddCategory
            show={postModal}
            categoryLists={categoryLists}
            onHide={() => setPostModal(false)}
          />
          {editModal && editData && (
            <EditCategory
              show={editModal}
              categoryLists={categoryLists}
              onHide={() => setEditModal(false)}
              editData={editData}
            />
          )}
          {/* {loader && <Spinner />} */}
        </div>
      )}
    </>
  );
}
