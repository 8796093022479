import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

const deshBoardApiURL = "https://api.baraatco.com/api/v1/admin/home";
const userListApiUrl = "https://api.baraatco.com/api/v1/admin/user";
const UserDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const servicesProviderListUrl = "https://api.baraatco.com/api/v1/getVendor";
const ServicesProviderDetailsUrl = "https://api.baraatco.com/api/v1/admin/user";
const CategoryListApiUrl = "https://api.baraatco.com/api/v1/category";
const BlockUserUrl = "https://api.baraatco.com/api/v1/admin/user";
const AddCategoryUrl = "https://api.baraatco.com/api/v1/category";
const AddServiceUrl = "https://api.baraatco.com/api/v1/admin/vendor";
const updateCategoryUrl = "https://api.baraatco.com/api/v1/category";
const updateServiceUrl = "https://api.baraatco.com/api/v1/admin/vendor";
const BlockServiceUrl = "https://api.baraatco.com/api/v1/admin/user";

// const role="";
const token = localStorage.getItem("baratUserToken");

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get(`admin/home`, {});
  return response;
}
export async function userManagementList(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `admin/user?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function viewUserDetails(id) {
  const response = await axiosInstance.get(`${UserDetailsUrl}/${id}`, {});
  return response;
}

export async function servicesProviderListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `getVendor?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

export async function servicesProviderDetailsApi(id) {
  const response = await axios.get(`${ServicesProviderDetailsUrl}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  });
  return response;
}

export async function categoryListApi(itemsPerPage, currentPage) {
  const response = await axiosInstance.get(
    `category?limit=${itemsPerPage}&offset=${currentPage}`,
    {}
  );
  return response;
}

// ================putAPI==========================
// const data={}
export async function blockUserApi(id, status) {
  // console.log(id,"id i api")
  const data = { status: status };
  const response = await axiosInstance.put(`${BlockUserUrl}/${id}`, data, {});
  return response;
}

export async function blockServiceApi(id, status) {
  // console.log(id,"id i api")
  const data = { status: status };
  const response = await axiosInstance.put(
    `${BlockServiceUrl}/${id}`,
    data,
    {}
  );
  return response;
}

export async function updateCategoryApi(id, file, name) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("category", name);
  // console.log(formData,"formData i api")
  // const data={status:status}
  const response = await axiosInstance.put(
    `${updateCategoryUrl}/${id}`,
    formData,
    {}
  );
  return response;
}

export async function editServiceApi(EditFormData) {
  const formData = new FormData();
  formData.append("file", EditFormData.file);
  formData.append("name", EditFormData.name);
  formData.append("email", EditFormData.email);
  formData.append("category", EditFormData.category);
  formData.append("number", EditFormData.phone);
  formData.append("password", EditFormData.password);
  formData.append("userId", EditFormData.userId);
  formData.append("instagram", EditFormData.instagramId);
  formData.append("website", EditFormData.website);
  formData.append("description", EditFormData.description);
  formData.append("countryCode", EditFormData.countryCode);

  console.log(EditFormData, "formData i api");
  // const data={status:status}
  const response = await axiosInstance.put(updateServiceUrl, formData, {});
  return response;
}
// ======================post=============================
export async function addCategoryApi(name, file) {
  console.log(name, file);
  const formData = new FormData();
  formData.append("file", file);
  formData.append("category", name);
  console.log(formData, "catdata i api");
  // const data={AddCategoryData};
  const response = await axiosInstance.post(AddCategoryUrl, formData, {});
  return response;
}

export async function addServiceApi(data) {
  const response = await axiosInstance.post(AddServiceUrl, data, {});
  return response;
}
export async function addMultiVendorApi(formData) {
  // const data = { vendors: formData };
  const response = await axiosInstance.post(
    "admin/addbulkVendor",
    formData,
    {}
  );
  return response;
}

//===============Delete============================================================
export async function deleteCategoryApi(id) {
  const response = await axiosInstance.delete(`category/${id}`);
  return response;
}
